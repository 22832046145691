import {
  HANDLE_NOTIFICATION,
  ASYNC_PROJECT_INIT,
  GET_PROJECTS_SUCCESS,
  GET_SINGLE_PROJECT_SUCCESS,
  ASYNC_SECONDARY_PROJECT_INIT,
  GET_PROJECT_BY_PILLAR_SUCCESS,
  DELETE_PROJECT_SUCCESS,
} from "actionTypes/projects";

const initialState = {
  loading: false,
  secondaryLoader: false,
  project: {
    header: "",
    subHeader: "",
    translatedHeader: "",
    introduction: "",
    objective: "",
    process: "",
    thumbnailDescription: "",
    pillerId: "",
    introductionImage: null,
    objectiveImage: null,
    processImage: null,
    thumbnailImage: null,
    Events: [],
    cordinator: [],
    heroImage: null,
  },
  notification: null,
  projectList: [],
  isDeleted: false,
};

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case ASYNC_PROJECT_INIT:
      return {
        ...state,
        loading: true,
        notification: null,
        isDeleted: false,
      };
    case ASYNC_SECONDARY_PROJECT_INIT:
      return {
        ...state,
        secondaryLoader: true,
        notification: null,
        isDeleted: false,
      };
    case HANDLE_NOTIFICATION:
      return {
        ...state,
        notification: payload.notification,
        [payload.loaderKey]: false,
      };
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projectList: payload,
        loading: false,
      };
    case GET_PROJECT_BY_PILLAR_SUCCESS:
      return {
        ...state,
        projectList: payload,
        secondaryLoader: false,
      };
    case GET_SINGLE_PROJECT_SUCCESS:
      return {
        ...state,
        project: { ...state.project, ...payload },
        loading: false,
      };
    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false,
      };
    default:
      return state;
  }
};
