import {
  ASYNC_BANNER_INIT,
  GET_BANNER_LIST_SUCCESS,
  HANDLE_NOTIFICATION,
  GET_SPLITTED_LIST_SUCCESS,
  INITIALIZE_BANNER,
} from "actionTypes/heroBanner";
import { isNotEmpty } from "shared/utils";

const initialState = {
  loading: false,
  bannerList: [],
  notification: null,
  bannerOne: {
    id: "",
    header: "",
    description: "",
    heroImage: null,
    readMoreLink: "",
    created: false,
  },
  bannerTwo: {
    id: "",
    header: "",
    description: "",
    heroImage: null,
    readMoreLink: "",
    created: false,
  },
};

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case ASYNC_BANNER_INIT:
      return {
        ...state,
        loading: true,
        notification: null,
      };
    case HANDLE_NOTIFICATION:
      return {
        ...state,
        notification: payload,
        loading: false,
      };
    case GET_BANNER_LIST_SUCCESS:
      return {
        ...state,
        bannerList: payload,
        loading: false,
      };
    case GET_SPLITTED_LIST_SUCCESS:
      return {
        ...state,
        bannerOne: {
          ...state.bannerOne,
          ...payload[0],
          created: isNotEmpty(payload[0]) ? true : false,
        },
        bannerTwo: {
          ...state.bannerTwo,
          ...payload[1],
          created: isNotEmpty(payload[1]) ? true : false,
        },
        loading: false,
      };
    case INITIALIZE_BANNER:
      return {
        ...state,
        bannerOne: {
          id: "",
          header: "",
          description: "",
          heroImage: null,
          readMoreLink: "",
          created: false,
        },
        bannerTwo: {
          id: "",
          header: "",
          description: "",
          heroImage: null,
          readMoreLink: "",
          created: false,
        },
      };
    default:
      return state;
  }
};
