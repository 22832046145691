import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import Routing from "./routes/index";
import configureStore from "./store";
import { Provider } from "react-redux";
import { isAuthUSer } from "actions/auth";
import { getHeaderItems } from "actions/header";

const store = configureStore({});

store.dispatch(isAuthUSer());
store.dispatch(getHeaderItems());

ReactDOM.render(
  <Provider store={store}>
    <Routing />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
