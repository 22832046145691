import {
  ASYNC_EVENTS_INIT,
  HANDLE_NOTIFICATION,
  GET_EVENTS_SUCCESS,
  GET_SINGLE_EVENT_SUCCESS,
  GET_UPCOMING_EVENTS_SUCCESS,
  GET_LATEST_EVENTS_SUCCESS,
  DELETE_EVENT_SUCCESS,
  INITIALIZE_NOTIFICATION,
} from "actionTypes/events";

const initialState = {
  loading: false,
  upcomingLoader: false,
  event: {
    headerTitle: "",
    headerSinhalaTitle: "",
    headerDescription: "",
    contentDescription: "",
    thumbnailDescription: "",
    thumbnailTitle: "",
    date: "",
    projectId: "",
    contentImage: "",
    thumbnailImage: "",
    subImages: [],
    EventImages: [],
    heroImage: null,
  },
  notification: null,
  eventList: [],
  upcomingEvents: [],
  latestEvents: [],
  isDeleted: false,
};

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case ASYNC_EVENTS_INIT:
      return {
        ...state,
        [payload]: true,
        notification: null,
        isDeleted: false,
      };
    case INITIALIZE_NOTIFICATION:
      return {
        ...state,
        notification: null,
        loading: false,
        isDeleted: false,
      };
    case HANDLE_NOTIFICATION:
      return {
        ...state,
        notification: payload.message,
        [payload.loaderKey]: false,
      };
    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        eventList: payload,
        loading: false,
      };
    case GET_SINGLE_EVENT_SUCCESS:
      return {
        ...state,
        event: {
          ...state.event,
          ...payload,
        },
        loading: false,
      };
    case GET_UPCOMING_EVENTS_SUCCESS:
      return {
        ...state,
        upcomingEvents: payload,
        upcomingLoader: false,
      };
    case GET_LATEST_EVENTS_SUCCESS:
      return {
        ...state,
        latestEvents: payload,
        loading: false,
      };
    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false,
      };
    default:
      return state;
  }
};
