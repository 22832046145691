import React, { useEffect } from "react";

import Header from "../header/header";
import Footer from "../footer/footer";

import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";

import "./styles.scss";

ReactGA.initialize("UA-206086521-2");

function Layout(props) {
  useEffect(() => {
    ReactGA.pageview(props.location.pathname + props.location.search);
  }, [props.location.pathname, props.location.search]);

  return (
    <div className="layout-container">
      <div className="layout-header">
        <Header />
      </div>
      <div className="layout-main">
        <div className="layout-content">{props.children}</div>
        <div className="layout-footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default withRouter(Layout);
