import axios from "service";

import {
  ASYNC_AUTH_INIT,
  HANDLE_NOTIFICATION,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  GET_USER_LIST,
  GET_USER_DETAILS_SUCCESS,
  GET_SHAREABLE_LIST_SUCCESS,
  ASYNC_USER_INIT,
  VERIFICATION_SUCCESS,
  SIGN_UP_SUCCESS,
  INITIALIZE_AUTH_PROCESS,
} from "actionTypes/auth";

export function initializeAuthProcess() {
  return (dispatch) => {
    dispatch({ type: INITIALIZE_AUTH_PROCESS });
  };
}

function setValidator() {
  return {
    type: ASYNC_USER_INIT,
  };
}

function setLoader(clearToken = false) {
  return {
    type: ASYNC_AUTH_INIT,
    payload: clearToken,
  };
}

export function notificationHandler(type, message) {
  return {
    type: HANDLE_NOTIFICATION,
    payload: {
      type,
      message,
    },
  };
}

export function getContacts(payload) {
  return (dispatch) => {
    dispatch(setLoader());

    axios
      .post("/registrar/invite", payload)
      .then(({ success, data }) => {
        dispatch(
          notificationHandler(
            success,
            success
              ? "Invitation sent successfully"
              : data.message
              ? data.message
              : "Something went wrong.Please try again"
          )
        );
      })
      .catch(() => {
        dispatch(
          notificationHandler(false, "Something went wrong.Please try again")
        );
      });
  };
}

export function getShareableList() {
  return (dispatch) => {
    dispatch(setLoader());

    axios
      .get("/user/sharableemails/list")
      .then(({ success, data }) => {
        if (success) {
          dispatch({ type: GET_SHAREABLE_LIST_SUCCESS, payload: data });
        } else {
          dispatch(
            notificationHandler(
              success,
              data.message
                ? data.message
                : "Something went wrong.Please try again"
            )
          );
        }
      })
      .catch(() => {
        dispatch(
          notificationHandler(false, "Something went wrong.Please try again")
        );
      });
  };
}

export function login(details) {
  return (dispatch) => {
    dispatch(setLoader());

    axios
      .post("/authenticate/login", details, { headers: { token: null } })
      .then(({ success, data }) => {
        if (success) {
          localStorage.setItem("sasnaka-tk", data.token);
          dispatch({ type: LOGIN_SUCCESS, payload: data });
        } else {
          dispatch(
            notificationHandler(
              success,
              data.message
                ? data.message
                : "Something went wrong.Please try again"
            )
          );
        }
      })
      .catch(() => {
        dispatch(
          notificationHandler(false, "Something went wrong.Please try again")
        );
      });
  };
}

export function logout() {
  return (dispatch) => {
    localStorage.removeItem("sasnaka-tk");
    dispatch({ type: LOGOUT_SUCCESS });
  };
}

export function getUserList() {
  return (dispatch) => {
    dispatch(setLoader());

    axios
      .get("/user/list")
      .then(({ success, data }) => {
        if (success) {
          dispatch({ type: GET_USER_LIST, payload: data });
        } else {
          dispatch(
            notificationHandler(
              success,
              data.message
                ? data.message
                : "Something went wrong.Please try again"
            )
          );
        }
      })
      .catch(() => {
        dispatch(
          notificationHandler(false, "Something went wrong.Please try again")
        );
      });
  };
}

export function getUserDetails(id) {
  return (dispatch) => {
    dispatch(setLoader());

    axios
      .get(`/user/account/${id}`)
      .then(({ success, data }) => {
        if (success) {
          dispatch({ type: GET_USER_DETAILS_SUCCESS, payload: data });
        } else {
          dispatch(
            notificationHandler(
              success,
              data.message
                ? data.message
                : "Something went wrong.Please try again"
            )
          );
        }
      })
      .catch(() => {
        dispatch(
          notificationHandler(false, "Something went wrong.Please try again")
        );
      });
  };
}

export function editUSerDetails(payload, id) {
  return (dispatch) => {
    dispatch(setLoader());

    axios
      .put(`/user/account/${id}`, payload)
      .then(({ success, data }) => {
        if (success) {
          axios
            .get(`/user/account/${id}`)
            .then(({ success, data }) => {
              if (success) {
                dispatch({ type: GET_USER_DETAILS_SUCCESS, payload: data });
              } else {
                dispatch(
                  notificationHandler(
                    success,
                    data.message
                      ? data.message
                      : "Something went wrong.Please try again"
                  )
                );
              }
            })
            .catch(() => {
              dispatch(
                notificationHandler(
                  false,
                  "Something went wrong.Please try again"
                )
              );
            });
        } else {
          dispatch(
            notificationHandler(
              success,
              data.message
                ? data.message
                : "Something went wrong.Please try again"
            )
          );
        }
      })
      .catch(() => {
        dispatch(
          notificationHandler(false, "Something went wrong.Please try again")
        );
      });
  };
}

export function updateUserRole(payload) {
  return (dispatch) => {
    dispatch(setLoader());

    axios
      .put("/user/role", payload)
      .then(({ success, data: updateData }) => {
        if (success) {
          axios
            .get("/user/list")
            .then(({ success, data }) => {
              if (success) {
                dispatch({ type: GET_USER_LIST, payload: data });
              }
              dispatch(
                notificationHandler(
                  success,
                  success
                    ? "Updated successfully"
                    : data.message
                    ? data.message
                    : "Something went wrong.Please try again"
                )
              );
            })
            .catch(() => {
              dispatch(
                notificationHandler(
                  false,
                  "Something went wrong.Please try again"
                )
              );
            });
        } else {
          dispatch(
            notificationHandler(
              success,
              updateData.message
                ? updateData.message
                : "Something went wrong.Please try again"
            )
          );
        }
      })
      .catch(() => {
        dispatch(
          notificationHandler(false, "Something went wrong.Please try again")
        );
      });
  };
}

export function isAuthUSer(details) {
  return (dispatch) => {
    dispatch(setValidator());

    axios
      .get("/authenticate/jwt")
      .then(({ success, data }) => {
        if (success) {
          dispatch({ type: LOGIN_SUCCESS, payload: data });
        } else {
          localStorage.removeItem("sasnaka-tk");
          dispatch({ type: LOGOUT_SUCCESS });
        }
      })
      .catch(() => {
        localStorage.removeItem("sasnaka-tk");
        dispatch({ type: LOGOUT_SUCCESS });
      });
  };
}

export function validateToken(token) {
  return (dispatch) => {
    dispatch(setLoader(true));
    localStorage.removeItem("sasnaka-tk");

    axios
      .get(`/authenticate/verify/${token}`, { headers: { token: "" } })
      .then(({ success, data }) => {
        if (success) {
          localStorage.removeItem("sasnaka-tk");
          dispatch({ type: VERIFICATION_SUCCESS, payload: data });
        } else {
          dispatch(
            notificationHandler(
              success,
              data.message
                ? data.message
                : "Something went wrong.Please try again"
            )
          );
        }
      })
      .catch(() => {
        dispatch(
          notificationHandler(false, "Something went wrong.Please try again")
        );
      });
  };
}

export function signup(details) {
  return (dispatch) => {
    dispatch(setLoader());

    axios
      .post("/authenticate/register", details, { headers: { token: null } })
      .then(({ success, data }) => {
        if (success) {
          dispatch({ type: SIGN_UP_SUCCESS, payload: data });
          console.log(data);
        } else {
          dispatch(
            notificationHandler(
              success,
              data.message
                ? data.message
                : "Something went wrong.Please try again"
            )
          );
        }
      })
      .catch(() => {
        dispatch(
          notificationHandler(false, "Something went wrong.Please try again")
        );
      });
  };
}
