import React from "react";
import { Link } from "react-router-dom";

import Layout from "components/dashboardLayout";
import notFoundImage from "../assets/404.svg";

export default function DashboardNotFound(props) {
  const styles = {
    textAlign: "center",
  };

  const {
    location: { pathname },
  } = props;
  return (
    <Layout path={pathname} notFound={true}>
      <h1 style={styles}>404!</h1>
      <div style={styles}>Sorry, page not found</div>
      <div
        style={{ width: `fit-content`, margin: `50px auto`, maxWidth: `90vw` }}
      >
        <img src={notFoundImage} alt="404" style={{ maxWidth: `100%` }} />
      </div>
      <div
        style={{
          display: `flex`,
          justifyContent: `center`,
          marginBottom: `50px`,
        }}
      >
        <Link
          to="/dashboard/projects"
          style={{
            padding: `10px 20px`,
            textAlign: `center`,
            marginRight: `20px`,
            backgroundColor: `#0098DA`,
            boxShadow: `0px 4px 8px #00000051`,
            color: `#ffffff`,
            textDecoration: `none`,
            fontWeight: `600`,
          }}
        >
          Back to Home
        </Link>
        <Link
          to="/contact-us"
          style={{
            padding: `10px 20px`,
            textAlign: `center`,
            backgroundColor: `#ffffff`,
            color: `#0098DA`,
            textDecoration: `none`,
            border: `2px solid #0098DA`,
            fontWeight: `600`,
          }}
        >
          Contact Us
        </Link>
      </div>
    </Layout>
  );
}
