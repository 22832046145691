import { ASYNC_REGISTER_INIT, HANDLE_NOTIFICATION } from "actionTypes/register";

const initialState = {
  loading: false,
  notification: null,
};

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case ASYNC_REGISTER_INIT:
      return {
        ...state,
        loading: true,
        notification: null,
      };
    case HANDLE_NOTIFICATION:
      return {
        ...state,
        notification: payload,
        loading: false,
      };
    default:
      return state;
  }
};
