import {
  ASYNC_SCHOLARSHIPS_INIT,
  HANDLE_NOTIFICATIONS,
  GET_SCHOLARSHIPS_LIST_SUCCESS,
  GET_SHARED_LIST_SUCCESS,
} from "actionTypes/scholarships";

const initialState = {
  loading: false,
  scholarships: [],
  notification: null,
  sharedList: [],
};

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case ASYNC_SCHOLARSHIPS_INIT:
      return {
        ...state,
        loading: true,
        notification: null,
      };
    case HANDLE_NOTIFICATIONS:
      return {
        ...state,
        notification: payload,
        loading: false,
      };
    case GET_SCHOLARSHIPS_LIST_SUCCESS:
      return {
        ...state,
        scholarships: payload,
        loading: false,
      };
    case GET_SHARED_LIST_SUCCESS:
      return {
        ...state,
        sharedList: payload,
        loading: false,
      };
    default:
      return state;
  }
};
