const PREFIX = `@@team/`;

export const ASYNC_TEAM_INIT = `${PREFIX}ASYNC_TEAM_INIT`;
export const HANDLE_NOTIFICATIONS = `${PREFIX}HANDLE_NOTIFICATIONS`;
export const ASYNC_COORDINATORS_INIT = `${PREFIX}ASYNC_COORDINATORS_INIT`;
export const HANDLE_COORDINATOR_NOTIFICATIONS = `${PREFIX}HANDLE_COORDINATOR_NOTIFICATIONS`;

export const GET_TEAM_SUCCESS = `${PREFIX}GET_TEAM_SUCCESS`;
export const GET_COORDINATORS_SUCCESS = `${PREFIX}GET_COORDINATORS_SUCCESS`;
export const GET_SINGLE_COORDINATOR_SUCCESS = `${PREFIX}GET_SINGLE_COORDINATOR_SUCCESS`;
export const DELETE_COORDINATOR_SUCCESS = `${PREFIX}DELETE_COORDINATOR_SUCCESS`;
