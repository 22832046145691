import {
  ASYNC_CONTACT_INIT,
  HANDLE_NOTIFICATION,
  GET_CONTACT_DETAILS_SUCCESS,
} from "actionTypes/contactUs";

const initialState = {
  loading: false,
  contactDetails: {
    postalCode: "",
    phone1: "",
    phone2: "",
    email: "",
  },
  notification: null,
};

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case ASYNC_CONTACT_INIT:
      return {
        ...state,
        loading: true,
        notification: null,
      };
    case HANDLE_NOTIFICATION:
      return {
        ...state,
        notification: payload,
        loading: false,
      };
    case GET_CONTACT_DETAILS_SUCCESS:
      return {
        ...state,
        contactDetails: { ...state.contactDetails, ...payload },
        loading: false,
      };
    default:
      return state;
  }
};
