import {
  GET_RESOURCE_LIST_SUCCESS,
  ASYNC_RESOURCE_INIT,
  HANDLE_NOTIFICATION,
  GET_SHARED_LIST_SUCCESS,
} from "actionTypes/resourceProvide";

const initialState = {
  loading: false,
  sharedLoading: false,
  resourceList: [],
  notification: null,
  sharedList: [],
};

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case ASYNC_RESOURCE_INIT:
      return {
        ...state,
        [payload]: true,
        notification: null,
      };
    case GET_RESOURCE_LIST_SUCCESS:
      return { ...state, resourceList: payload, loading: false };
    case HANDLE_NOTIFICATION:
      return {
        ...state,
        notification: payload.notification,
        [payload.loader]: false,
      };
    case GET_SHARED_LIST_SUCCESS:
      return {
        ...state,
        sharedList: payload,
        sharedLoading: false,
      };
    default:
      return state;
  }
};
