const PREFIX = `@@events/`;

export const ASYNC_EVENTS_INIT = `${PREFIX}ASYNC_EVENTS_INIT`;
export const HANDLE_NOTIFICATION = `${PREFIX}HANDLE_NOTIFICATION`;
export const INITIALIZE_NOTIFICATION = `${PREFIX}INITIALIZE_NOTIFICATION`;

export const GET_EVENTS_SUCCESS = `${PREFIX}GET_EVENTS_SUCCESS`;
export const GET_SINGLE_EVENT_SUCCESS = `${PREFIX}GET_SINGLE_EVENT_SUCCESS`;
export const GET_UPCOMING_EVENTS_SUCCESS = `${PREFIX}GET_UPCOMING_EVENTS_SUCCESS`;
export const GET_LATEST_EVENTS_SUCCESS = `${PREFIX}GET_LATEST_EVENTS_SUCCESS`;
export const DELETE_EVENT_SUCCESS = `${PREFIX}DELETE_EVENT_SUCCESS`;
