import {
  ASYNC_AUTH_INIT,
  HANDLE_NOTIFICATION,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  GET_USER_LIST,
  GET_USER_DETAILS_SUCCESS,
  GET_SHAREABLE_LIST_SUCCESS,
  ASYNC_USER_INIT,
  VERIFICATION_SUCCESS,
  SIGN_UP_SUCCESS,
  INITIALIZE_AUTH_PROCESS,
} from "actionTypes/auth";
import USER_ID from "constants/roles";
import { isNotEmpty } from "shared/utils";

const initialState = {
  loading: false,
  validating: false,
  notification: null,
  isAuthenticatedUser: false,
  details: {
    id: "",
    name: "",
    email: "",
    profileImage: "",
    contactNumber: "",
  },
  userList: [],
  shareableList: [],
  userRoleId: 5,
  verifiedToken: false,
  registeredSuccessfully: false,
};

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case ASYNC_AUTH_INIT:
      return {
        ...state,
        loading: true,
        notification: null,
        verifiedToken: payload ? false : state.verifiedToken,
        registeredSuccessfully: payload ? false : state.registeredSuccessfully,
      };
    case ASYNC_USER_INIT:
      return {
        ...state,
        validating: true,
        notification: null,
      };
    case INITIALIZE_AUTH_PROCESS:
      return {
        ...state,
        notification: null,
      };
    case HANDLE_NOTIFICATION:
      return {
        ...state,
        notification: payload,
        loading: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticatedUser: true,
        details: {
          ...state.details,
          ...payload,
        },
        loading: false,
        userRoleId:
          USER_ID[
            isNotEmpty(payload.roleId) ? payload.roleId : "EDITOR_LEVELD"
          ],
        validating: false,
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        isAuthenticatedUser: false,
        details: {
          ...state.details,
          ...payload,
        },
        loading: false,
        userRoleId:
          USER_ID[
            isNotEmpty(payload.roleId) ? payload.roleId : "EDITOR_LEVELD"
          ],
        validating: false,
        registeredSuccessfully: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticatedUser: false,
        details: {
          name: "",
          email: "",
          profileImage: "",
        },
        loading: false,
        userRoleId: 5,
        validating: false,
      };
    case GET_USER_LIST:
      return {
        ...state,
        userList: payload,
        loading: false,
      };
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        details: {
          ...state.details,
          ...payload,
        },
        loading: false,
      };
    case GET_SHAREABLE_LIST_SUCCESS:
      return {
        ...state,
        shareableList: payload,
        loading: false,
      };
    case VERIFICATION_SUCCESS:
      return {
        ...state,
        verifiedToken: true,
        loading: false,
      };
    default:
      return state;
  }
};
