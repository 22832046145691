import React, { Fragment, PureComponent } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Logo from "../../shared/logo/logo.png";
import steam from "./images/STEAM.svg";
import leadership from "./images/leadership.svg";
import entrepreneur from "./images/entrepreneur.svg";
import community from "./images/community.svg";

import { PILLARS_FOR_FILTERS } from "constants/organization";
import { isNotEmpty } from "shared/utils";

import "./styles.scss";

class Header extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isExpanded: false,
      aboutUs: false,
      programmes: false,
      mobileItems: null,
      socialMediaExpanded: false,
      showMediaLinks: false,
      showMobileAboutUs: false,
    };

    this.expandItem = this.expandItem.bind(this);
    this.setMobileItem = this.setMobileItem.bind(this);
    this.onChangeBurgerMenu = this.onChangeBurgerMenu.bind(this);
  }

  expandItem(field, secondaryField) {
    this.setState({
      ...this.state,
      ...field,
      ...secondaryField,
    });
  }

  setMobileItem(item) {
    const { mobileItems } = this.state;

    if (mobileItems === item) {
      this.setState({
        ...this.state,
        mobileItems: null,
      });
    } else {
      this.setState({
        ...this.state,
        mobileItems: item,
      });
    }
  }

  onChangeBurgerMenu() {
    const { isOpen } = this.state;

    this.setState({
      ...this.state,
      isOpen: !isOpen,
      isExpanded: false,
      aboutUs: false,
      programmes: false,
      mobileItems: null,
      socialMediaExpanded: false,
      showMediaLinks: false,
      showMobileAboutUs: false,
    });
  }

  render() {
    const {
      isOpen,
      isExpanded,
      aboutUs,
      programmes,
      mobileItems,
      showMediaLinks,
      showMobileAboutUs,
    } = this.state;
    const { loading, projects } = this.props;

    let steamItems = [];
    let entrepreneurItems = [];
    let leadershipItems = [];
    let communityItems = [];

    projects.length > 0 &&
      projects.map((project) => {
        switch (true) {
          case project.piller === PILLARS_FOR_FILTERS.STEAM:
            return (steamItems = isNotEmpty(project.projects)
              ? project.projects
              : []);
          case project.piller === PILLARS_FOR_FILTERS.ENTREPRENEURSHIP:
            return (entrepreneurItems = isNotEmpty(project.projects)
              ? project.projects
              : []);
          case project.piller === PILLARS_FOR_FILTERS.LEADERSHIP_AND_GROOMING:
            return (leadershipItems = isNotEmpty(project.projects)
              ? project.projects
              : []);
          case project.piller === PILLARS_FOR_FILTERS.SOCIAL_AND_COMMUNITY:
            return (communityItems = isNotEmpty(project.projects)
              ? project.projects
              : []);

          default:
            return null;
        }
      });

    return (
      <div className="header-container">
        <Link to="/">
          <div className="image-wrapper">
            <img src={Logo} alt="logo" className="image-logo" />
          </div>
        </Link>
        <div className="link-wrapper">
          <div className="icon-wrapper">
            <div className="icon-border"></div>
          </div>
          <div className="icon-wrapper">
            <a href="https://www.youtube.com/channel/UCWxCdX625pl6Z0hoGQMPcmw">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24.115"
                height="16.854"
                viewBox="0 0 24.115 16.854"
                className="icon-youTube"
              >
                <path
                  d="M23.618,8.026a3.022,3.022,0,0,0-2.1-2.1c-1.88-.5-9.444-.5-9.444-.5s-7.546-.01-9.444.5a3.022,3.022,0,0,0-2.1,2.1,31.408,31.408,0,0,0-.525,5.835,31.408,31.408,0,0,0,.525,5.813,3.022,3.022,0,0,0,2.1,2.1c1.878.5,9.444.5,9.444.5s7.545,0,9.444-.5a3.022,3.022,0,0,0,2.1-2.1,31.408,31.408,0,0,0,.5-5.813,31.408,31.408,0,0,0-.5-5.835ZM9.661,17.471v-7.23l6.3,3.621Z"
                  transform="translate(-0.007 -5.424)"
                  fill="#304170"
                />
              </svg>
            </a>
          </div>
          <div className="icon-wrapper">
            <div className="icon-border"></div>
          </div>
          <div className="icon-wrapper">
            <a href="https://www.linkedin.com/in/sasnaka-sansada-6050401b1/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                className="icon-linked-in"
              >
                <path
                  d="M3.805,17H.281V5.651H3.805ZM2.041,4.1A2.051,2.051,0,1,1,4.082,2.042,2.058,2.058,0,0,1,2.041,4.1ZM17,17H13.48V11.476c0-1.317-.027-3.005-1.832-3.005-1.832,0-2.113,1.431-2.113,2.911V17H6.013V5.651h3.38V7.2h.049a3.7,3.7,0,0,1,3.335-1.833c3.567,0,4.223,2.349,4.223,5.4V17Z"
                  fill="#304170"
                />
              </svg>
            </a>
          </div>
          <div className="icon-wrapper">
            <div className="icon-border"></div>
          </div>
          <div className="icon-wrapper">
            <a href="https://www.facebook.com/sasnaka/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9.105"
                height="17"
                viewBox="0 0 9.105 17"
                className="icon-facebook"
              >
                <path
                  d="M10.118,9.563l.472-3.077H7.638v-2A1.538,1.538,0,0,1,9.372,2.827h1.342V.208A16.366,16.366,0,0,0,8.332,0C5.9,0,4.312,1.474,4.312,4.141V6.486h-2.7V9.563h2.7V17H7.638V9.563Z"
                  transform="translate(-1.609)"
                  fill="#304170"
                />
              </svg>
            </a>
          </div>
          <div className="icon-wrapper">
            <div className="icon-border"></div>
          </div>
          <div className="icon-wrapper">
            <a href="https://www.instagram.com/sasnakasansada/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.101"
                height="17.104"
                viewBox="0 0 17.101 17.104"
                className="icon-instagram"
              >
                <path
                  d="M8.551,0C6.228,0,5.938.011,5.026.051a6.3,6.3,0,0,0-2.076.4,4.187,4.187,0,0,0-1.515.986A4.172,4.172,0,0,0,.449,2.95a6.288,6.288,0,0,0-.4,2.076C.009,5.938,0,6.228,0,8.551s.011,2.613.051,3.525a6.31,6.31,0,0,0,.4,2.076,4.194,4.194,0,0,0,.986,1.515,4.181,4.181,0,0,0,1.515.986,6.312,6.312,0,0,0,2.076.4c.912.043,1.2.051,3.525.051s2.613-.011,3.525-.051a6.327,6.327,0,0,0,2.076-.4,4.37,4.37,0,0,0,2.5-2.5,6.31,6.31,0,0,0,.4-2.076c.043-.912.051-1.2.051-3.525s-.011-2.613-.051-3.525a6.325,6.325,0,0,0-.4-2.076,4.2,4.2,0,0,0-.986-1.515A4.166,4.166,0,0,0,14.151.449a6.29,6.29,0,0,0-2.076-.4C11.164.009,10.874,0,8.551,0Zm0,1.539c2.282,0,2.555.011,3.456.051a4.711,4.711,0,0,1,1.587.3,2.814,2.814,0,0,1,1.623,1.623A4.722,4.722,0,0,1,15.511,5.1c.041.9.05,1.173.05,3.456s-.011,2.555-.053,3.456a4.813,4.813,0,0,1-.3,1.587,2.715,2.715,0,0,1-.641.985,2.668,2.668,0,0,1-.983.639,4.755,4.755,0,0,1-1.593.294c-.908.041-1.175.05-3.462.05s-2.555-.011-3.462-.053a4.848,4.848,0,0,1-1.593-.3,2.648,2.648,0,0,1-.983-.641,2.6,2.6,0,0,1-.641-.983,4.853,4.853,0,0,1-.3-1.593c-.032-.9-.043-1.175-.043-3.452s.011-2.555.043-3.464a4.848,4.848,0,0,1,.3-1.592A2.535,2.535,0,0,1,2.491,2.5a2.529,2.529,0,0,1,.983-.64,4.732,4.732,0,0,1,1.583-.3c.909-.032,1.176-.043,3.462-.043l.032.021Zm0,2.621a4.391,4.391,0,1,0,4.391,4.391A4.391,4.391,0,0,0,8.551,4.16Zm0,7.242a2.85,2.85,0,1,1,2.85-2.85A2.85,2.85,0,0,1,8.551,11.4Zm5.591-7.415a1.026,1.026,0,1,1-1.026-1.025A1.027,1.027,0,0,1,14.141,3.987Z"
                  fill="#304170"
                />
              </svg>
            </a>
          </div>
          <div className="icon-wrapper">
            <div className="icon-border"></div>
          </div>
          <div className="icon-wrapper">
            <a href="https://mobile.twitter.com/sasnakasansada">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21.004"
                height="17.072"
                viewBox="0 0 21.004 17.072"
                className="icon-twitter"
              >
                <path
                  d="M23.575,6.838a8.615,8.615,0,0,1-2.475.679,4.323,4.323,0,0,0,1.895-2.384,8.626,8.626,0,0,1-2.736,1.046,4.313,4.313,0,0,0-7.343,3.931,12.233,12.233,0,0,1-8.882-4.5A4.315,4.315,0,0,0,5.366,11.36a4.291,4.291,0,0,1-1.952-.539c0,.018,0,.036,0,.054A4.312,4.312,0,0,0,6.871,15.1a4.316,4.316,0,0,1-1.946.074A4.314,4.314,0,0,0,8.95,18.168,8.645,8.645,0,0,1,3.6,20.013a8.739,8.739,0,0,1-1.028-.06,12.2,12.2,0,0,0,6.606,1.936A12.178,12.178,0,0,0,21.437,9.627q0-.28-.013-.558a8.755,8.755,0,0,0,2.15-2.231Z"
                  transform="translate(-2.571 -4.816)"
                  fill="#304170"
                />
              </svg>
            </a>
          </div>
          <div className="icon-wrapper">
            <div className="icon-border"></div>
          </div>
        </div>
        <div className="menu-container">
          <ul className="menu-list">
            <li className="menu-item">
              <Link to="/">Home</Link>
            </li>
            <li className="menu-item">
              <div
                className="special-item"
                onClick={() =>
                  this.expandItem({ aboutUs: !aboutUs }, { programmes: false })
                }
              >
                About Us
                <span className="special-icon-wrapper">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12.714"
                    height="7.841"
                    viewBox="0 0 13.714 7.841"
                  >
                    <path
                      id="Icon_ionic-ios-arrow-down"
                      data-name="Icon ionic-ios-arrow-down"
                      d="M13.047,16.724l5.186-5.19a.976.976,0,0,1,1.384,0,.988.988,0,0,1,0,1.388L13.741,18.8a.978.978,0,0,1-1.352.029l-5.916-5.9a.98.98,0,1,1,1.384-1.388Z"
                      transform="translate(-6.188 -11.246)"
                      fill="#304170"
                    />
                  </svg>
                </span>
              </div>
              {aboutUs && (
                <div className="header-item-expanded">
                  <Link to="/about-us">About Us</Link>
                  <Link to="/our-team">Our Team</Link>
                </div>
              )}
            </li>
            <li className="menu-item">
              <div
                className="special-item"
                onClick={() =>
                  this.expandItem(
                    { programmes: !programmes },
                    { aboutUs: false }
                  )
                }
              >
                Our Projects
                <span className="special-icon-wrapper">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12.714"
                    height="7.841"
                    viewBox="0 0 13.714 7.841"
                  >
                    <path
                      id="Icon_ionic-ios-arrow-down"
                      data-name="Icon ionic-ios-arrow-down"
                      d="M13.047,16.724l5.186-5.19a.976.976,0,0,1,1.384,0,.988.988,0,0,1,0,1.388L13.741,18.8a.978.978,0,0,1-1.352.029l-5.916-5.9a.98.98,0,1,1,1.384-1.388Z"
                      transform="translate(-6.188 -11.246)"
                      fill="#304170"
                    />
                  </svg>
                </span>
              </div>
              {programmes && (
                <div className="header-item-programmes">
                  <div className="programme-item">
                    <Link to="/pillars/STEAM">
                      <div className="programme-item-logo">
                        <img src={steam} alt="steam" />
                      </div>
                    </Link>
                    <div className="programme-item-header">STEAM</div>
                    <div className="header-border">
                      <div className="header-line" />
                    </div>
                    <div className="project-list">
                      {loading ? (
                        <div className="project-spinner" />
                      ) : (
                        <Fragment>
                          {steamItems.length > 0 &&
                            steamItems.map((item, index) => {
                              return (
                                <div className="project-list-item" key={index}>
                                  <Link
                                    to={`/pillars/STEAM/project/${item.id}`}
                                  >
                                    {item.header}
                                  </Link>
                                </div>
                              );
                            })}
                        </Fragment>
                      )}
                    </div>
                  </div>
                  <div className="programme-item">
                    <Link to="/pillars/LEADERSHIP_AND_GROOMING">
                      <div className="programme-item-logo">
                        <img src={leadership} alt="leadership" />
                      </div>
                    </Link>
                    <div className="programme-item-header">
                      Leadership and Grooming
                    </div>
                    <div className="header-border">
                      <div className="header-line" />
                    </div>
                    <div className="project-list">
                      {loading ? (
                        <div className="project-spinner" />
                      ) : (
                        <Fragment>
                          {leadershipItems.length > 0 &&
                            leadershipItems.map((item, index) => {
                              return (
                                <div className="project-list-item" key={index}>
                                  <Link
                                    to={`/pillars/LEADERSHIP_AND_GROOMING/project/${item.id}`}
                                  >
                                    {item.header}
                                  </Link>
                                </div>
                              );
                            })}
                        </Fragment>
                      )}
                    </div>
                  </div>
                  <div className="programme-item">
                    <Link to="/pillars/ENTREPRENEURSHIP">
                      <div className="programme-item-logo">
                        <img src={entrepreneur} alt="entrepreneur" />
                      </div>
                    </Link>
                    <div className="programme-item-header">
                      Entrepreneurship
                    </div>
                    <div className="header-border">
                      <div className="header-line" />
                    </div>
                    <div className="project-list">
                      {loading ? (
                        <div className="project-spinner" />
                      ) : (
                        <Fragment>
                          {entrepreneurItems.length > 0 &&
                            entrepreneurItems.map((item, index) => {
                              return (
                                <div className="project-list-item" key={index}>
                                  <Link
                                    to={`/pillars/ENTREPRENEURSHIP/project/${item.id}`}
                                  >
                                    {item.header}
                                  </Link>
                                </div>
                              );
                            })}
                        </Fragment>
                      )}
                    </div>
                  </div>
                  <div className="programme-item">
                    <Link to="/pillars/SOCIAL_AND_COMMUNITY">
                      <div className="programme-item-logo">
                        <img src={community} alt="community" />
                      </div>
                    </Link>
                    <div className="programme-item-header">
                      Social and Community
                    </div>
                    <div className="header-border">
                      <div className="header-line" />
                    </div>
                    <div className="project-list">
                      {loading ? (
                        <div className="project-spinner" />
                      ) : (
                        <Fragment>
                          {communityItems.length > 0 &&
                            communityItems.map((item, index) => {
                              return (
                                <div className="project-list-item" key={index}>
                                  <Link
                                    to={`/pillars/LEADERSHIP_AND_GROOMING/project/${item.id}`}
                                  >
                                    {item.header}
                                  </Link>
                                </div>
                              );
                            })}
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </li>
            <li className="menu-item">
              <Link to="/support-us">Support Us</Link>
            </li>
            <li className="menu-item">
              <Link to="/contact-us">Contact Us</Link>
            </li>
          </ul>
        </div>
        <div className="mini-menu-container">
          <ul className="menu-list">
            <li className="menu-list" onClick={() => this.onChangeBurgerMenu()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22.76"
                height="15.75"
                viewBox="0 0 22.76 15.75"
              >
                <g
                  id="Icon_ionic-ios-menu"
                  data-name="Icon ionic-ios-menu"
                  transform="translate(-4.5 -10.125)"
                >
                  <path
                    id="Path_17541"
                    data-name="Path 17541"
                    d="M26.311,12.375H5.448A1.051,1.051,0,0,1,4.5,11.25h0a1.051,1.051,0,0,1,.948-1.125H26.311a1.051,1.051,0,0,1,.948,1.125h0A1.051,1.051,0,0,1,26.311,12.375Z"
                    fill="#304170"
                  />
                  <path
                    id="Path_17542"
                    data-name="Path 17542"
                    d="M26.311,19.125H5.448A1.051,1.051,0,0,1,4.5,18h0a1.051,1.051,0,0,1,.948-1.125H26.311A1.051,1.051,0,0,1,27.26,18h0A1.051,1.051,0,0,1,26.311,19.125Z"
                    fill="#304170"
                  />
                  <path
                    id="Path_17543"
                    data-name="Path 17543"
                    d="M26.311,25.875H5.448A1.051,1.051,0,0,1,4.5,24.75h0a1.051,1.051,0,0,1,.948-1.125H26.311a1.051,1.051,0,0,1,.948,1.125h0A1.051,1.051,0,0,1,26.311,25.875Z"
                    fill="#304170"
                  />
                </g>
              </svg>
            </li>
          </ul>
        </div>
        <div
          className={`header-dropdown ${
            isOpen ? "header-dropdown-open" : undefined
          }`}
        >
          <Link to="/">
            <div className="header-dropdown-item">Home</div>
          </Link>
          <div className="programmes">
            <div
              className="header-dropdown-item item-without-link"
              onClick={() =>
                this.setState({
                  ...this.state,
                  showMobileAboutUs: !showMobileAboutUs,
                })
              }
            >
              About Us
              <span className="select-icon-wrapper">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13.714"
                  height="7.841"
                  viewBox="0 0 13.714 7.841"
                >
                  <path
                    id="Icon_ionic-ios-arrow-down"
                    data-name="Icon ionic-ios-arrow-down"
                    d="M13.047,16.724l5.186-5.19a.976.976,0,0,1,1.384,0,.988.988,0,0,1,0,1.388L13.741,18.8a.978.978,0,0,1-1.352.029l-5.916-5.9a.98.98,0,1,1,1.384-1.388Z"
                    transform="translate(-6.188 -11.246)"
                    fill="#232630"
                  />
                </svg>
              </span>
            </div>
            {showMobileAboutUs && (
              <div className="additional-options">
                <Link to="/about-us">
                  <div className="pillar-option">About Us</div>
                </Link>
                <Link to="/our-team">
                  <div className="pillar-option">Our Team</div>
                </Link>
              </div>
            )}
          </div>
          <div className="programmes">
            <div
              className="header-dropdown-item item-without-link"
              onClick={() =>
                this.setState({ ...this.state, isExpanded: !isExpanded })
              }
            >
              Our Projects
              <span className="select-icon-wrapper">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13.714"
                  height="7.841"
                  viewBox="0 0 13.714 7.841"
                >
                  <path
                    id="Icon_ionic-ios-arrow-down"
                    data-name="Icon ionic-ios-arrow-down"
                    d="M13.047,16.724l5.186-5.19a.976.976,0,0,1,1.384,0,.988.988,0,0,1,0,1.388L13.741,18.8a.978.978,0,0,1-1.352.029l-5.916-5.9a.98.98,0,1,1,1.384-1.388Z"
                    transform="translate(-6.188 -11.246)"
                    fill="#232630"
                  />
                </svg>
              </span>
            </div>
            {isExpanded && (
              <div className="additional-options">
                <div className="pillar-option">
                  <Link to="/pillars/STEAM">STEAM</Link>
                  <span
                    className="pillar-option-action"
                    onClick={() =>
                      this.setMobileItem(PILLARS_FOR_FILTERS.STEAM)
                    }
                  >
                    <span className="select-icon-wrapper">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13.714"
                        height="7.841"
                        viewBox="0 0 13.714 7.841"
                      >
                        <path
                          id="Icon_ionic-ios-arrow-down"
                          data-name="Icon ionic-ios-arrow-down"
                          d="M13.047,16.724l5.186-5.19a.976.976,0,0,1,1.384,0,.988.988,0,0,1,0,1.388L13.741,18.8a.978.978,0,0,1-1.352.029l-5.916-5.9a.98.98,0,1,1,1.384-1.388Z"
                          transform="translate(-6.188 -11.246)"
                          fill="#232630"
                        />
                      </svg>
                    </span>
                  </span>
                </div>
                {mobileItems === PILLARS_FOR_FILTERS.STEAM &&
                  steamItems.length > 0 && (
                    <div className="expanded-projects">
                      {steamItems.map((item, index) => {
                        return (
                          <Link
                            to={`/pillars/STEAM/project/${item.id}`}
                            key={index}
                            className="expanded-projects-link"
                          >
                            <div className="expanded-projects-item">
                              {item.header}
                            </div>
                          </Link>
                        );
                      })}
                    </div>
                  )}
                <div className="pillar-option">
                  <Link to="/pillars/ENTREPRENEURSHIP">Entrepreneurship</Link>
                  <span
                    className="pillar-option-action"
                    onClick={() =>
                      this.setMobileItem(PILLARS_FOR_FILTERS.ENTREPRENEURSHIP)
                    }
                  >
                    <span className="select-icon-wrapper">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13.714"
                        height="7.841"
                        viewBox="0 0 13.714 7.841"
                      >
                        <path
                          id="Icon_ionic-ios-arrow-down"
                          data-name="Icon ionic-ios-arrow-down"
                          d="M13.047,16.724l5.186-5.19a.976.976,0,0,1,1.384,0,.988.988,0,0,1,0,1.388L13.741,18.8a.978.978,0,0,1-1.352.029l-5.916-5.9a.98.98,0,1,1,1.384-1.388Z"
                          transform="translate(-6.188 -11.246)"
                          fill="#232630"
                        />
                      </svg>
                    </span>
                  </span>
                </div>
                {mobileItems === PILLARS_FOR_FILTERS.ENTREPRENEURSHIP &&
                  entrepreneurItems.length > 0 && (
                    <div className="expanded-projects">
                      {entrepreneurItems.map((item, index) => {
                        return (
                          <Link
                            to={`/pillars/ENTREPRENEURSHIP/project/${item.id}`}
                            key={index}
                            className="expanded-projects-link"
                          >
                            <div className="expanded-projects-item">
                              {item.header}
                            </div>
                          </Link>
                        );
                      })}
                    </div>
                  )}
                <div className="pillar-option">
                  <Link to="/pillars/LEADERSHIP_AND_GROOMING">
                    Leadership and Grooming
                  </Link>
                  <span className="pillar-option-action">
                    <span
                      className="select-icon-wrapper"
                      onClick={() =>
                        this.setMobileItem(
                          PILLARS_FOR_FILTERS.LEADERSHIP_AND_GROOMING
                        )
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13.714"
                        height="7.841"
                        viewBox="0 0 13.714 7.841"
                      >
                        <path
                          id="Icon_ionic-ios-arrow-down"
                          data-name="Icon ionic-ios-arrow-down"
                          d="M13.047,16.724l5.186-5.19a.976.976,0,0,1,1.384,0,.988.988,0,0,1,0,1.388L13.741,18.8a.978.978,0,0,1-1.352.029l-5.916-5.9a.98.98,0,1,1,1.384-1.388Z"
                          transform="translate(-6.188 -11.246)"
                          fill="#232630"
                        />
                      </svg>
                    </span>
                  </span>
                </div>
                {mobileItems === PILLARS_FOR_FILTERS.LEADERSHIP_AND_GROOMING &&
                  leadershipItems.length > 0 && (
                    <div className="expanded-projects">
                      {leadershipItems.map((item, index) => {
                        return (
                          <Link
                            to={`/pillars/LEADERSHIP_AND_GROOMING/project/${item.id}`}
                            key={index}
                            className="expanded-projects-link"
                          >
                            <div className="expanded-projects-item">
                              {item.header}
                            </div>
                          </Link>
                        );
                      })}
                    </div>
                  )}
                <div className="pillar-option">
                  <Link to="/pillars/SOCIAL_AND_COMMUNITY">
                    Social and Community
                  </Link>
                  <span
                    className="pillar-option-action"
                    onClick={() =>
                      this.setMobileItem(
                        PILLARS_FOR_FILTERS.SOCIAL_AND_COMMUNITY
                      )
                    }
                  >
                    <span className="select-icon-wrapper">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="13.714"
                        height="7.841"
                        viewBox="0 0 13.714 7.841"
                      >
                        <path
                          id="Icon_ionic-ios-arrow-down"
                          data-name="Icon ionic-ios-arrow-down"
                          d="M13.047,16.724l5.186-5.19a.976.976,0,0,1,1.384,0,.988.988,0,0,1,0,1.388L13.741,18.8a.978.978,0,0,1-1.352.029l-5.916-5.9a.98.98,0,1,1,1.384-1.388Z"
                          transform="translate(-6.188 -11.246)"
                          fill="#232630"
                        />
                      </svg>
                    </span>
                  </span>
                </div>
                {mobileItems === PILLARS_FOR_FILTERS.SOCIAL_AND_COMMUNITY &&
                  communityItems.length > 0 && (
                    <div className="expanded-projects">
                      {communityItems.map((item, index) => {
                        return (
                          <Link
                            to={`/pillars/SOCIAL_AND_COMMUNITY/project/${item.id}`}
                            key={index}
                            className="expanded-projects-link"
                          >
                            <div className="expanded-projects-item">
                              {item.header}
                            </div>
                          </Link>
                        );
                      })}
                    </div>
                  )}
              </div>
            )}
          </div>
          <Link to="/support-us">
            <div className="header-dropdown-item">Support Us</div>
          </Link>
          <Link to="contact-us">
            <div className="header-dropdown-item">Contact Us</div>
          </Link>
          <div className="programmes">
            <div
              className="header-dropdown-item item-without-link"
              onClick={() =>
                this.setState({
                  ...this.state,
                  showMediaLinks: !showMediaLinks,
                })
              }
            >
              Social Media
              <span className="select-icon-wrapper">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13.714"
                  height="7.841"
                  viewBox="0 0 13.714 7.841"
                >
                  <path
                    id="Icon_ionic-ios-arrow-down"
                    data-name="Icon ionic-ios-arrow-down"
                    d="M13.047,16.724l5.186-5.19a.976.976,0,0,1,1.384,0,.988.988,0,0,1,0,1.388L13.741,18.8a.978.978,0,0,1-1.352.029l-5.916-5.9a.98.98,0,1,1,1.384-1.388Z"
                    transform="translate(-6.188 -11.246)"
                    fill="#232630"
                  />
                </svg>
              </span>
            </div>
            {showMediaLinks && (
              <div className="additional-options">
                <a href="https://www.youtube.com/channel/UCWxCdX625pl6Z0hoGQMPcmw">
                  <div className="pillar-option">YouTube</div>
                </a>
                <a href="https://www.facebook.com/sasnaka/">
                  <div className="pillar-option">Facebook</div>
                </a>
                <a href="https://www.linkedin.com/in/sasnaka-sansada-6050401b1/">
                  <div className="pillar-option">Linked in</div>
                </a>
                <a href="https://www.instagram.com/sasnakasansada/">
                  <div className="pillar-option">Instagram</div>
                </a>
                <a href="https://mobile.twitter.com/sasnakasansada">
                  <div className="pillar-option">Twitter</div>
                </a>
              </div>
            )}
          </div>
        </div>
        {isOpen && (
          <div
            className="header-backdrop"
            onClick={() => this.setState({ ...this.state, isOpen: !isOpen })}
          />
        )}
        {aboutUs || programmes ? (
          <div
            className="header-main-backdrop"
            onClick={() =>
              this.setState({
                ...this.state,
                aboutUs: false,
                programmes: false,
              })
            }
          />
        ) : (
          <div />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.header.loading,
    projects: state.header.projects,
  };
};

const Actions = {};

export default connect(mapStateToProps, Actions)(Header);
