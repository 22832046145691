const PREFIX = `@@feedbacks/`;

export const ASYNC_FEEDBACKS_INIT = `${PREFIX}ASYNC_FEEDBACKS_INIT`;
export const ASYNC_VISIBLE_INIT = `${PREFIX}ASYNC_VISIBLE_INIT`;
export const HANDLE_NOTIFICATION = `${PREFIX}HANDLE_NOTIFICATION`;
export const HANDLE_VISIBLE_NOTIFICATION = `${PREFIX}HANDLE_VISIBLE_NOTIFICATION`;

export const GET_FEEDBACK_LIST_SUCCESS = `${PREFIX}GET_FEEDBACK_LIST_SUCCESS`;
export const GET_VISIBLE_FEEDBACK_LIST_SUCCESS = `${PREFIX}GET_VISIBLE_FEEDBACK_LIST_SUCCESS`;
export const GET_SHARED_LIST_SUCCESS = `${PREFIX}GET_SHARED_LIST_SUCCESS`;
