import React from "react";

import Header from "components/dashboardHeader";
import Sidebar from "components/dashboardSidebar";

import "./styles.scss";
import { Link } from "react-router-dom";

export default function DashboardLayout(props) {
  return (
    <div className="dashboard-layout-container">
      <div className="dashboard-header">
        <Header />
      </div>
      <div className="dashboard-main">
        <div className="dashboard-sidebar">
          <Sidebar path={props.path} notFound={props.notFound} />
        </div>
        <div className="dashboard-content">
          <div className="breadcrumb">
            {props.breadcrumbs.map((item, index) => {
              return (
                <div className="breadcrumb-item" key={index}>
                  {index !== 0 && ">"}
                  {index < props.breadcrumbs.length - 1 ? (
                    <Link to={item.path} className="breadcrumb-item-link">
                      {item.name}
                    </Link>
                  ) : (
                    <div className="breadcrumb-item-link">{item.name}</div>
                  )}
                </div>
              );
            })}
          </div>
          <div>{props.children}</div>
        </div>
      </div>
    </div>
  );
}

DashboardLayout.defaultProps = {
  path: "",
  notFound: false,
  breadcrumbs: [],
};
