import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import RootReducer from "./reducers";

export default function (initialState = {}) {
  const middlewareEnhancer = applyMiddleware(thunk);
  const composeEnhancers = composeWithDevTools(middlewareEnhancer);

  const store = createStore(RootReducer(), initialState, composeEnhancers);

  return store;
}
