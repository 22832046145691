export function isEmpty(string) {
  return string === null || string === "" || string === undefined;
}

export function isNotEmpty(string) {
  return !isEmpty(string);
}

export function queryParamsToObjects(query) {
  const objectString = query.split("?");
  const objectArray = objectString.length > 1 ? objectString[1].split("&") : [];

  let result = {};
  objectArray.length > 0 &&
    objectArray.map((filter) => {
      if (isNotEmpty(filter)) {
        const keyAndValue = filter.split("=");
        if (
          keyAndValue.length > 1 &&
          isNotEmpty(keyAndValue[0]) &&
          isNotEmpty(keyAndValue[1])
        ) {
          result[keyAndValue[0]] = decodeURIComponent(keyAndValue[1]);
        }
      }
      return null;
    });

  return result;
}

export function ObjectsToQueryParams(object) {
  if (isNotEmpty(object)) {
    const keys = Object.keys(object);
    let formattedArray = [];

    keys.length > 0 &&
      keys.map((item) => {
        return formattedArray.push(
          `${encodeURIComponent([item])}=${encodeURIComponent(object[item])}`
        );
      });

    const searchParam =
      formattedArray.length > 0 ? formattedArray.join("&") : "";

    return `${"?" + searchParam.toString()}`;
  } else return "";
}

export function formatDate(date) {
  let processDate = new Date(date),
    month = "" + (processDate.getMonth() + 1),
    day = "" + processDate.getDate(),
    year = processDate.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("/");
}

export function formatInputDate(date) {
  let processDate = new Date(date),
    month = "" + (processDate.getMonth() + 1),
    day = "" + processDate.getDate(),
    year = processDate.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function formatDateAndMonth(date, character = "/") {
  let processDate = new Date(date),
    month = "" + (processDate.getMonth() + 1),
    day = "" + processDate.getDate();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [month, day].join(character);
}

export function formatURL(url = null) {
  let updatedUrl = url;

  const domain = isNotEmpty(url) ? url.substring(0, 5) : null;

  if (isNotEmpty(url) && isNotEmpty(domain) && domain === "http:") {
    updatedUrl = url.replace("http", "https");
  }

  return updatedUrl;
}
