import React, { PureComponent, Fragment, Suspense } from "react";
import { connect } from "react-redux";

import { HashRouter, Switch, Route } from "react-router-dom";

import Routes from "routes/routes";
import Loader from "components/loader/index";
import NotFoundPage from "shared/pages/notFoundPage";
import DashboardNotFound from "shared/pages/dashboardNotFound";
import PrivateRoute from "./privateRoute";

class Routing extends PureComponent {
  render() {
    const { isAuth, userRoleId, loading } = this.props;
    return (
      <Fragment>
        {!loading ? (
          <HashRouter basename="/">
            <Suspense fallback={<Loader />}>
              <Switch>
                {Routes.map(
                  (
                    { path, exact, component: Component, auth, role },
                    index
                  ) => {
                    if (auth) {
                      return (
                        <PrivateRoute
                          key={index}
                          path={path}
                          exact={exact}
                          Component={Component}
                          roleId={userRoleId}
                          isAuth={isAuth}
                          routeId={role}
                        />
                      );
                    }
                    return (
                      <Route
                        key={index}
                        path={path}
                        exact={exact}
                        render={(props) => <Component {...props} />}
                      />
                    );
                  }
                )}
                <Route path="/dashboard" component={DashboardNotFound} />
                <Route component={NotFoundPage} />
              </Switch>
            </Suspense>
          </HashRouter>
        ) : (
          <Loader />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuth: state.auth.isAuthenticatedUser,
    userRoleId: state.auth.userRoleId,
    loading: state.auth.validating,
  };
};

const Actions = {};

export default connect(mapStateToProps, Actions)(Routing);
