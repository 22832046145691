import {
  ASYNC_FEEDBACKS_INIT,
  GET_FEEDBACK_LIST_SUCCESS,
  HANDLE_NOTIFICATION,
  GET_VISIBLE_FEEDBACK_LIST_SUCCESS,
  ASYNC_VISIBLE_INIT,
  HANDLE_VISIBLE_NOTIFICATION,
  GET_SHARED_LIST_SUCCESS,
} from "actionTypes/feedback";

const initialState = {
  loading: false,
  feedbackList: [],
  notification: null,
  visibleFeedbacks: [],
  visibleLoading: false,
  visibleNotification: null,
  sharedList: [],
  sharedLoading: false,
};

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case ASYNC_FEEDBACKS_INIT:
      return {
        ...state,
        [payload]: true,
        notification: null,
      };
    case ASYNC_VISIBLE_INIT:
      return {
        ...state,
        visibleLoading: true,
        visibleNotification: null,
      };
    case HANDLE_NOTIFICATION:
      return {
        ...state,
        notification: payload.notification,
        [payload.loader]: false,
      };
    case HANDLE_VISIBLE_NOTIFICATION:
      return {
        ...state,
        visibleNotification: payload,
        visibleLoading: false,
      };
    case GET_FEEDBACK_LIST_SUCCESS:
      return {
        ...state,
        feedbackList: payload,
        loading: false,
      };
    case GET_VISIBLE_FEEDBACK_LIST_SUCCESS:
      return {
        ...state,
        visibleFeedbacks: payload,
        visibleLoading: false,
      };
    case GET_SHARED_LIST_SUCCESS:
      return {
        ...state,
        sharedList: payload,
        sharedLoading: false,
      };
    default:
      return state;
  }
};
