import { lazy } from "react";

export default [
  {
    path: "/",
    exact: true,
    auth: false,
    component: lazy(() => import("modules/homePage/homePage")),
  },
  {
    path: "/support-us",
    exact: true,
    auth: false,
    component: lazy(() => import("modules/supportUs/supportUs")),
  },
  {
    path: "/about-us",
    exact: true,
    auth: false,
    component: lazy(() => import("modules/aboutUs/aboutUs")),
  },
  {
    path: "/upcomingEvents",
    exact: true,
    auth: false,
    component: lazy(() => import("modules/upcomingEvents/upcomingEvents")),
  },
  {
    path: "/notifications-news/:id",
    exact: true,
    auth: false,
    component: lazy(() => import("modules/notifications/notifications")),
  },
  {
    path: "/upcomingEvents/:id",
    exact: true,
    auth: false,
    component: lazy(() => import("modules/upcomingEvents/viewUpcomingEvent")),
  },
  {
    path: "/pillars/:id",
    exact: true,
    auth: false,
    component: lazy(() => import("modules/pillars/viewSinglePillar")),
  },
  {
    path: "/coordinators",
    exact: true,
    auth: false,
    component: lazy(() =>
      import("modules/projectCoordinators/projectCoordinators")
    ),
  },
  {
    path: "/event/:id",
    exact: true,
    auth: false,
    component: lazy(() => import("modules/eventDetails/eventDetails")),
  },
  {
    path: "/contact-us",
    exact: true,
    auth: false,
    component: lazy(() => import("modules/contactUs/contactUs")),
  },
  {
    path: "/pillars/:pillarId/project/:id",
    exact: true,
    auth: false,
    component: lazy(() => import("modules/pillars/viewSingleProject")),
  },
  {
    path: "/latest-events",
    exact: true,
    auth: false,
    component: lazy(() => import("modules/latestEvents/latestEvents")),
  },
  {
    path: "/our-team",
    exact: true,
    auth: false,
    component: lazy(() => import("modules/team/team")),
  },
  {
    path: "/donate",
    exact: true,
    auth: false,
    component: lazy(() => import("shared/pages/underConstruction")),
  },
  {
    path: "/volunteer",
    exact: true,
    auth: false,
    component: lazy(() => import("modules/volunteers/index")),
  },

  // Dashboard
  {
    path: "/dashboard/login",
    exact: true,
    role: 1,
    auth: false,
    component: lazy(() => import("modules/dashboard/login/login")),
  },
  {
    path: "/dashboard/user",
    exact: true,
    role: 1,
    auth: true,
    component: lazy(() => import("modules/dashboard/userDetails/userDetails")),
  },
  {
    path: "/dashboard/projects",
    exact: true,
    role: 1,
    auth: true,
    component: lazy(() => import("modules/dashboard/projects/projects")),
  },
  {
    path: "/dashboard/projects/create",
    exact: true,
    role: 1,
    auth: true,
    component: lazy(() => import("modules/dashboard/projects/addProject")),
  },
  {
    path: "/dashboard/projects/update/:id",
    exact: true,
    role: 1,
    auth: true,
    component: lazy(() => import("modules/dashboard/projects/editProject")),
  },
  {
    path: "/dashboard/projects/update/:projectId/newEvent",
    exact: true,
    role: 1,
    auth: true,
    component: lazy(() => import("modules/dashboard/events/addEvent")),
  },
  {
    path: "/dashboard/projects/update/:projectId/event/:eventId",
    exact: true,
    role: 1,
    auth: true,
    component: lazy(() => import("modules/dashboard/events/editEvent")),
  },
  {
    path: "/dashboard/team",
    exact: true,
    role: 1,
    auth: true,
    component: lazy(() => import("modules/dashboard/team/topBoard")),
  },
  {
    path: "/dashboard/team/coordinator/create",
    exact: true,
    role: 1,
    auth: true,
    component: lazy(() => import("modules/dashboard/team/createCoordinator")),
  },
  {
    path: "/dashboard/team/alumni/create",
    exact: true,
    role: 1,
    auth: true,
    component: lazy(() => import("modules/dashboard/team/createAlumni")),
  },
  {
    path: "/dashboard/hero-banner",
    exact: true,
    role: 1,
    auth: true,
    component: lazy(() => import("modules/dashboard/heroBanner/heroBanner")),
  },
  {
    path: "/dashboard/notification",
    exact: true,
    role: 1,
    auth: true,
    component: lazy(() =>
      import("modules/dashboard/notification/notification")
    ),
  },
  {
    path: "/dashboard/scholarship",
    exact: true,
    role: 1,
    auth: true,
    component: lazy(() =>
      import("modules/dashboard/scholarships/scholarships")
    ),
  },
  {
    path: "/dashboard/feedback",
    exact: true,
    role: 1,
    auth: true,
    component: lazy(() => import("modules/dashboard/feedbacks/feedbacks")),
  },
  {
    path: "/dashboard/resource-provide",
    exact: true,
    role: 1,
    auth: true,
    component: lazy(() => import("modules/dashboard/resourceProvide")),
  },
  {
    path: "/dashboard/contact-us",
    exact: true,
    role: 1,
    auth: true,
    component: lazy(() =>
      import("modules/dashboard/contactDetails/contactDetails")
    ),
  },
  {
    path: "/dashboard/site-role",
    exact: true,
    role: 1,
    auth: true,
    component: lazy(() => import("modules/dashboard/userRoles/userRoles")),
  },
  {
    path: "/dashboard/donation",
    exact: true,
    role: 1,
    auth: true,
    // @TODO - Remove under construction page after donation page will be implemented
    // component: lazy(() => import("modules/dashboard/donations/dashboard")),
    component: lazy(() => import("shared/pages/dashboardUnderConstruction")),
  },
  {
    path: "/dashboard/team/coordinator/update/:id",
    exact: true,
    role: 1,
    auth: true,
    component: lazy(() => import("modules/dashboard/team/editCoordinator")),
  },
  {
    path: "/dashboard/team/alumni/create/update/:id",
    exact: true,
    role: 1,
    auth: true,
    component: lazy(() => import("modules/dashboard/team/editCoordinator")),
  },
  {
    path: "/dashboard/register",
    exact: true,
    role: 1,
    auth: true,
    component: lazy(() => import("modules/dashboard/register/register")),
  },
  {
    path: "/dashboard/signup/:id",
    exact: true,
    role: 1,
    auth: false,
    component: lazy(() => import("modules/dashboard/signUp")),
  },
  {
    path: "/dashboard/team/media/create",
    exact: true,
    role: 1,
    auth: true,
    component: lazy(() => import("modules/dashboard/team/createMedia")),
  },
  {
    path: "/dashboard/team/media/update/:id",
    exact: true,
    role: 1,
    auth: true,
    component: lazy(() => import("modules/dashboard/team/editMedia")),
  },
  {
    path: "/dashboard/upcoming-projects/create",
    exact: true,
    role: 1,
    auth: true,
    component: lazy(() => import("modules/dashboard/upcomingEvent/addEvent")),
  },
  {
    path: "/dashboard/upcoming-projects/update/:id",
    exact: true,
    role: 1,
    auth: true,
    component: lazy(() => import("modules/dashboard/upcomingEvent/editEvent")),
  },
  {
    path: "/dashboard/upcoming-projects",
    exact: true,
    role: 1,
    auth: true,
    component: lazy(() =>
      import("modules/dashboard/upcomingEvent/viewUpcomingEvents")
    ),
  },
  {
    path: "/dashboard/volunteers",
    exact: true,
    role: 1,
    auth: true,
    component: lazy(() => import("modules/dashboard/volunteers/volunteers")),
  },
];
