export default [
  { name: "Project", id: "PROJECT", path: "/dashboard/projects" },
  {
    name: "Upcoming Event",
    id: "UPCOMING_EVENT",
    path: "/dashboard/upcoming-projects",
  },
  { name: "Feedback", id: "FEEDBACK", path: "/dashboard/feedback" },
  { name: "Scholarship", id: "SCHOLARSHIP", path: "/dashboard/scholarship" },
  { name: "Volunteer", id: "VOLUNTEER", path: "/dashboard/volunteers" },
  {
    name: "Resource Provide",
    id: "RESOURCE_PROVIDE",
    path: "/dashboard/resource-provide",
  },
  { name: "Team", id: "TEAM", path: "/dashboard/team" },
  { name: "Hero Banner", id: "HERO_BANNER", path: "/dashboard/hero-banner" },
  { name: "Notification", id: "NOTIFICATION", path: "/dashboard/notification" },
  { name: "Contact Us", id: "CONTACT_US", path: "/dashboard/contact-us" },
  { name: "Site Role", id: "SITE_ROLE", path: "/dashboard/site-role" },
  { name: "Donation & Basic", id: "DONATION", path: "/dashboard/donation" },
  { name: "Register", id: "REGISTER", path: "/dashboard/register" },
];
