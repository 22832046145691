import React from "react";
import { Redirect, Route } from "react-router-dom";

export default function PrivateRoute({
  path,
  exact,
  Component,
  roleId,
  isAuth,
  routeId,
}) {
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) =>
        isAuth ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/dashboard/login" }} />
        )
      }
    />
  );
}
