import { combineReducers } from "redux";

import resource from "reducers/resourceProvider";
import projects from "reducers/projects";
import events from "reducers/events";
import feedbacks from "reducers/feedback";
import scholarships from "reducers/scholarships";
import contactUs from "reducers/contactUs";
import notifications from "reducers/notifications";
import team from "reducers/team";
import register from "reducers/register";
import banners from "reducers/heroBanner";
import auth from "reducers/auth";
import media from "reducers/media";
import upcomingEvents from "reducers/upcoming";
import header from "reducers/header";
import volunteer from "reducers/volunteer";

export default () =>
  combineReducers({
    resource,
    projects,
    events,
    feedbacks,
    scholarships,
    contactUs,
    notifications,
    team,
    register,
    banners,
    auth,
    media,
    upcomingEvents,
    header,
    volunteer,
  });
