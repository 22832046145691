import axios from "service";

import {
  ASYNC_HEADER_INIT,
  HANDLE_LOADER,
  GET_HEADER_ITEMS_SUCCESS,
} from "actionTypes/header";

function setLoader() {
  return {
    type: ASYNC_HEADER_INIT,
  };
}

function loaderHandler(type, message) {
  return {
    type: HANDLE_LOADER,
  };
}

export function getHeaderItems() {
  return (dispatch) => {
    dispatch(setLoader());

    axios
      .get(`/project/list/`)
      .then(({ success, data }) => {
        if (success) {
          dispatch({
            type: GET_HEADER_ITEMS_SUCCESS,
            payload: success ? data : null,
          });
        } else {
          dispatch(loaderHandler());
        }
      })
      .catch(() => {
        dispatch(loaderHandler());
      });
  };
}
