import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

import Logo from "../../shared/logo/logo.png";

import "./styles.scss";

class Footer extends PureComponent {
  render() {
    return (
      <div className="footer-container">
        <div className="footer-main-row">
          <div className="logo-container">
            <img src={Logo} alt="logo" className="image-logo" />
          </div>
          <div className="footer-sub-container footer-nav-container">
            <div className="footer-headers">Quick Links</div>
            <div className="footer-links-wrapper">
              <ul className="footer-links">
                <li className="footer-link">
                  <Link to="/">Home</Link>
                </li>
                <li className="footer-link">
                  <Link to="/about-us">About Us</Link>
                </li>
                <li className="footer-link">
                  <Link to="/donate">Donate</Link>
                </li>
                <li className="footer-link">
                  <Link to="/contact-us">Contact Us</Link>
                </li>
              </ul>
              <ul className="footer-links footer-links-right">
                <li className="footer-link">
                  <Link to="/latest-events">Latest Events</Link>
                </li>
                <li className="footer-link">
                  <Link to="/our-team">Our Team</Link>
                </li>
                <li className="footer-link">
                  <Link to="/coordinators">Project Coordinators</Link>
                </li>
                <li className="footer-link">
                  <Link to="/support-us">Support Us</Link>
                </li>
              </ul>
            </div>
            <div className="footer-line-wrapper">
              <div className="footer-straight-line" />
            </div>
            <div className="member-login">
              <Link to="/dashboard/login">Member Login</Link>
            </div>
          </div>
          <div className="footer-sub-container footer-more-container">
            <div className="footer-headers">More Details</div>
            <ul className="footer-links">
              <li className="footer-link">
                <a href="https://www.youtube.com/channel/UCWxCdX625pl6Z0hoGQMPcmw">
                  YouTube
                </a>
              </li>
              <li className="footer-link">
                <a href="https://www.facebook.com/sasnaka/">Facebook</a>
              </li>
              <li className="footer-link">
                <a href="https://www.linkedin.com/in/sasnaka-sansada-6050401b1/">
                  Linked In
                </a>
              </li>
              <li className="footer-link">
                <a href="https://www.instagram.com/sasnakasansada/">
                  Instagram
                </a>
              </li>
              <li className="footer-link">
                <a href="https://mobile.twitter.com/sasnakasansada">Twitter</a>
              </li>
            </ul>
          </div>
          <div className="footer-contacts-container">
            <div className="footer-headers">Contact Us</div>
            <div className="footer-table">
              <table>
                <tbody>
                  <tr>
                    <td className="table-icon">
                      <div className="icon-wrapper">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="11.9"
                          height="17"
                          viewBox="0 0 11.9 17"
                        >
                          <path
                            id="Icon_material-location-on"
                            data-name="Icon material-location-on"
                            d="M13.45,3A5.946,5.946,0,0,0,7.5,8.95C7.5,13.413,13.45,20,13.45,20S19.4,13.413,19.4,8.95A5.946,5.946,0,0,0,13.45,3Zm0,8.075A2.125,2.125,0,1,1,15.575,8.95,2.126,2.126,0,0,1,13.45,11.075Z"
                            transform="translate(-7.5 -3)"
                            fill="#fff"
                          />
                        </svg>
                      </div>
                    </td>
                    <td>
                      Sasnaka Sansada foundation
                      <br />
                      155/34, Pannipitiya Road,
                      <br />
                      Battaramulla
                      <br />
                      Sri Lanka
                    </td>
                  </tr>
                  <tr>
                    <td className="table-icon">
                      <div className="tel-wrapper">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.539"
                          height="20.553"
                          viewBox="0 0 20.539 20.553"
                        >
                          <path
                            id="Icon_zocial-call"
                            data-name="Icon zocial-call"
                            d="M3.3,6.148A1.2,1.2,0,0,1,3.607,5.5L6.052,3.052q.286-.244.428.081L8.456,6.841a.533.533,0,0,1-.1.632l-.9.9A1.014,1.014,0,0,0,7.172,9,3.11,3.11,0,0,0,7.8,10.569,13.4,13.4,0,0,0,9.046,12.2l.632.651c.19.19.434.425.734.7a12.3,12.3,0,0,0,1.476,1.1,3.3,3.3,0,0,0,1.62.682.887.887,0,0,0,.652-.265l1.06-1.059a.448.448,0,0,1,.611-.081l3.566,2.1a.3.3,0,0,1,.163.214.26.26,0,0,1-.081.234l-2.445,2.445a1.2,1.2,0,0,1-.651.3,4.928,4.928,0,0,1-2.252-.275,10.188,10.188,0,0,1-2.322-1.11q-1.07-.692-1.986-1.405T8.355,15.214l-.53-.51q-.2-.2-.54-.56T6.113,12.739a20.46,20.46,0,0,1-1.446-2.047A11.943,11.943,0,0,1,3.607,8.42,4.832,4.832,0,0,1,3.3,6.148Z"
                            transform="matrix(0.951, 0.309, -0.309, 0.951, 2.845, -3.813)"
                            fill="#fff"
                          />
                        </svg>
                      </div>
                    </td>
                    <td className="table-tel">+94 112 223 445</td>
                  </tr>
                  <tr>
                    <td className="table-icon">
                      <div className="icon-wrapper">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26.42"
                          height="17.834"
                          viewBox="0 0 26.42 17.834"
                        >
                          <path
                            id="Icon_zocial-email"
                            data-name="Icon zocial-email"
                            d="M.072,20.323V5.648q0-.025.076-.484l8.637,7.389L.174,20.832a2.159,2.159,0,0,1-.1-.51ZM1.218,4.144a1.1,1.1,0,0,1,.433-.076H24.913a1.443,1.443,0,0,1,.459.076l-8.662,7.414-1.146.917-2.268,1.86-2.268-1.86-1.146-.917Zm.025,17.682,8.688-8.331,3.363,2.726,3.363-2.726,8.688,8.331a1.223,1.223,0,0,1-.433.076H1.652a1.154,1.154,0,0,1-.408-.076ZM17.8,12.552l8.611-7.389a1.521,1.521,0,0,1,.076.484V20.323a1.953,1.953,0,0,1-.076.51Z"
                            transform="translate(-0.072 -4.068)"
                            fill="#fff"
                          />
                        </svg>
                      </div>
                    </td>
                    <td>info@sasnaka.org</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="footer-bottom-row">
          <div className="straight-line"></div>
          <div style={{ fontSize: `12px` }}>
            2020 All Rights Reserved <span>&#169;</span> Sasnaka Sansada
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
