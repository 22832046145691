import {
  ASYNC_TEAM_INIT,
  HANDLE_NOTIFICATIONS,
  GET_TEAM_SUCCESS,
  ASYNC_COORDINATORS_INIT,
  HANDLE_COORDINATOR_NOTIFICATIONS,
  GET_COORDINATORS_SUCCESS,
  GET_SINGLE_COORDINATOR_SUCCESS,
  DELETE_COORDINATOR_SUCCESS,
} from "actionTypes/team";

const initialState = {
  loading: false,
  notification: null,
  secondaryLoader: false,
  secondaryNotification: null,
  teamList: [],
  coordinatorList: [],
  coordinator: null,
  isDeleted: false,
};

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case ASYNC_TEAM_INIT:
      return {
        ...state,
        loading: true,
        notification: null,
        secondaryNotification: null,
      };
    case ASYNC_COORDINATORS_INIT:
      return {
        ...state,
        secondaryLoader: true,
        secondaryNotification: null,
        notification: null,
        isDeleted: false,
      };
    case HANDLE_COORDINATOR_NOTIFICATIONS:
      return {
        ...state,
        secondaryLoader: false,
        secondaryNotification: payload,
      };
    case HANDLE_NOTIFICATIONS:
      return {
        ...state,
        notification: payload,
        loading: false,
      };
    case GET_TEAM_SUCCESS:
      return {
        ...state,
        teamList: payload,
        loading: false,
      };
    case GET_COORDINATORS_SUCCESS:
      return {
        ...state,
        coordinatorList: payload,
        secondaryLoader: false,
      };
    case GET_SINGLE_COORDINATOR_SUCCESS:
      return {
        ...state,
        coordinator: payload,
        loading: false,
      };
    case DELETE_COORDINATOR_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false,
      };
    default:
      return state;
  }
};
