const PREFIX = "@@projects/";

export const ASYNC_PROJECT_INIT = `${PREFIX}ASYNC_PROJECT_INIT`;
export const HANDLE_NOTIFICATION = `${PREFIX}HANDLE_NOTIFICATION`;
export const ASYNC_SECONDARY_PROJECT_INIT = `${PREFIX}ASYNC_SECONDARY_PROJECT_INIT`;

export const GET_PROJECTS_SUCCESS = `${PREFIX}GET_PROJECTS_SUCCESS`;
export const GET_SINGLE_PROJECT_SUCCESS = `${PREFIX}GET_SINGLE_PROJECT_SUCCESS`;
export const GET_PROJECT_BY_PILLAR_SUCCESS = `${PREFIX}GET_PROJECT_BY_PILLAR_SUCCESS`;
export const DELETE_PROJECT_SUCCESS = `${PREFIX}DELETE_PROJECT_SUCCESS`;
