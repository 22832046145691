import React, { PureComponent } from "react";

import "./styles.scss";

class Loader extends PureComponent {
  render() {
    return (
      <div className="loader-wrapper">
        <div className="loader" />
      </div>
    );
  }
}

export default Loader;
