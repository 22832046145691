import {
  ASYNC_NOTIFICATIONS_INIT,
  HANDLE_NOTIFICATION,
  GET_NOTIFICATION_LIST_SUCCESS,
  GET_NOTIFICATION_SUCCESS,
} from "actionTypes/notifications";
import { isNotEmpty } from "shared/utils";

const initialState = {
  loading: false,
  notificationOne: {
    header: "",
    subHeader: "",
    description: "",
    subDescription: "",
    bannerImage: "",
    portraitImage: null,
    active: false,
    attatchments: [],
    created: false,
    id: "",
    Attatchments: [],
  },
  notificationTwo: {
    header: "",
    subHeader: "",
    description: "",
    subDescription: "",
    bannerImage: "",
    portraitImage: null,
    active: false,
    attatchments: [],
    created: false,
    id: "",
    Attatchments: [],
  },
  notification: null,
  news: {},
  notificationList: [],
};

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case ASYNC_NOTIFICATIONS_INIT:
      return {
        ...state,
        loading: true,
        notification: null,
      };
    case HANDLE_NOTIFICATION:
      return {
        ...state,
        notification: payload,
        loading: false,
      };
    case GET_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        notificationOne: {
          ...state.notificationOne,
          ...payload[0],
          attatchments:
            isNotEmpty(payload[0]) && isNotEmpty(payload[0].Attatchments)
              ? payload[0].Attatchments
              : [],
          created: isNotEmpty(payload[0]) ? true : false,
        },
        notificationTwo: {
          ...state.notificationTwo,
          ...payload[1],
          attatchments:
            isNotEmpty(payload[1]) && isNotEmpty(payload[1].Attatchments)
              ? payload[1].Attatchments
              : [],
          created: isNotEmpty(payload[1]) ? true : false,
        },
        notificationList: payload,
        loading: false,
      };
    case GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        news: payload,
        loading: false,
      };
    default:
      return state;
  }
};
