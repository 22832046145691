import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import propTypes from "prop-types";

import sidebarContent from "constants/dashboard";

import { isNotEmpty } from "shared/utils";

import "./styles.scss";

class Sidebar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedItem: sidebarContent[0].id,
    };

    this.changeActiveTab = this.changeActiveTab.bind(this);
  }

  componentDidMount() {
    const { path, notFound } = this.props;

    const pathStrings = path.split("/");
    const filteredPath =
      pathStrings.length > 3
        ? pathStrings.filter((item, index) => index <= 2)
        : pathStrings;
    const sidebarPath = filteredPath.join("/");

    const selectedItem = sidebarContent.find(
      (item) => item.path === sidebarPath
    );

    this.setState({
      ...this.state,
      selectedItem: isNotEmpty(selectedItem)
        ? notFound
          ? ""
          : selectedItem.id
        : notFound
        ? ""
        : sidebarContent[0].id,
    });
  }

  changeActiveTab(selectedId) {
    this.setState({
      ...this.state,
      selectedItem: selectedId,
    });
  }

  render() {
    const { selectedItem } = this.state;

    return (
      <div className="sidebar">
        <div className="content">
          {sidebarContent.map((item, index) => {
            return (
              <Link to={item.path} key={index}>
                <div
                  className={`item ${
                    selectedItem === item.id ? "active-item" : undefined
                  }`}
                  onClick={() => this.changeActiveTab(item.id)}
                >
                  {item.name}
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    );
  }
}

Sidebar.prototypes = {
  selectedItem: propTypes.string,
};

export default Sidebar;
