import {
  ASYNC_UPCOMING_EVENTS_INIT,
  GET_PUBLISHED_EVENTS_SUCCESS,
  HANDLE_NOTIFICATION,
  GET_UPCOMING_LIST_SUCCESS,
  GET_UPCOMING_EVENT_SUCCESS,
  DELETE_UPCOMING_EVENT_SUCCESS,
} from "../actionTypes/upcoming";

const initialState = {
  loading: false,
  upcomingEvent: {
    headerTitle: "",
    headerSinhalaTitle: "",
    headerDescription: "",
    contentDescription: "",
    thumbnailDescription: "",
    thumbnailTitle: "",
    startDate: "",
    endDate: "",
    contentImage: null,
    thumbnailImage: null,
    heroImage: null,
    UpcomingEventImages: [],
  },
  notification: null,
  upcomingList: [],
  publishedList: [],
  isDeleted: false,
};

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case ASYNC_UPCOMING_EVENTS_INIT:
      return {
        ...state,
        loading: true,
        notification: null,
      };
    case HANDLE_NOTIFICATION:
      return {
        ...state,
        loading: false,
        notification: payload,
      };
    case GET_UPCOMING_EVENT_SUCCESS:
      return {
        ...state,
        upcomingEvent: {
          ...state.upcomingEvent,
          ...payload,
        },
        loading: false,
        isDeleted: false,
      };
    case GET_UPCOMING_LIST_SUCCESS:
      return {
        ...state,
        upcomingList: payload,
        loading: false,
        isDeleted: false,
      };
    case GET_PUBLISHED_EVENTS_SUCCESS:
      return {
        ...state,
        publishedList: payload,
        loading: false,
        isDeleted: false,
      };
    case DELETE_UPCOMING_EVENT_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false,
      };
    default:
      return state;
  }
};
