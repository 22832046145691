import {
  ASYNC_HEADER_INIT,
  HANDLE_LOADER,
  GET_HEADER_ITEMS_SUCCESS,
} from "actionTypes/header";

const initialState = {
  loading: false,
  projects: [],
};

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case ASYNC_HEADER_INIT:
      return {
        ...state,
        loading: true,
      };
    case HANDLE_LOADER:
      return {
        ...state,
        loading: false,
      };
    case GET_HEADER_ITEMS_SUCCESS:
      return {
        ...state,
        projects: payload,
        loading: false,
      };
    default:
      return state;
  }
};
