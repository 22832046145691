import axios from "axios";

const token = JSON.parse(JSON.stringify(localStorage.getItem("sasnaka-tk")));

const instance = axios.create({
  baseURL: `https://api.sasnaka.org/api`,
  headers: { token: token },
});

instance.defaults.headers["Content-Type"] = "application/json";
instance.defaults.headers["Accept"] = "*/*";

instance.interceptors.request.use(
  (request) => {
    const newToken = JSON.parse(
      JSON.stringify(localStorage.getItem("sasnaka-tk"))
    );
    request.headers["token"] = newToken;
    instance.defaults.headers["token"] = newToken;
    return request;
  },
  (error) => {
    return error;
  }
);

instance.interceptors.response.use(
  (response) => {
    return response.data
      ? { success: true, data: response.data }
      : { success: false, data: null };
  },
  (error) => {
    return {
      success: false,
      data: error.response ? error.response.data : null,
    };
  }
);

export default instance;
