export const ORGANIZATION = {
  name: "Sasnaka Sansada",
  shortName: "Sasnaka",
  longName: "Sasnaka Sansada Foundation",
  address: {
    line1: "155/34, Pannipitiya Rd,",
    line2: "Battaramulla,",
    line3: "Sri Lanka",
  },
  phone: "(+94)11 288 2015",
  mobile: "(+94)11 288 2015",
  email: "info@sasnaka.org",
};

export const ACCOUNT = [
  { name: "Account Name", value: "Sasnaka Sansada" },
  { name: "Account NO", value: "8020003825" },
  { name: "Bank", value: "Commercial Bank of Ceylon" },
  { name: "Branch", value: "City Branch" },
  { name: "Branch Code", value: "7056002" },
  { name: "Swift code", value: "CCEYLKLX" },
];

export const ABOUT = {
  staticsContent:
    "The Foundation Stone of Sasnaka Sansada was laid in 1997 by a group of young individuals who were the Sri Sudassanarama Temple in Battaramulla, who subsequently got involved with a number of socio-religious activities with the involvement of the village and other well wishers.",
};

export const OUR_STORY = [
  `The Foundation Stone of Sasnaka Sansada was laid in 1997 by a group of young individuals who were gathered to the Sri Sudassanarama Temple in Battaramulla, who subsequently got involved with a number of socio-religious activities with the involvement of the villages and other well wishers.The initial deliberations of Sasnaka Sansada aimed to inculcate Buddhist spiritual essence among the younger generation who were in the membership and get their involvement in the social and community acts.`,
  `After the initiation, Sasnaka Sansada expanded its activities to the other region and parts of the island.First of such activity was at a very rural school in Ratnapura District where the foundation provided educational aids to school children and spent the entire day with them educating and inspiring those children with social events.Many books, stationary items, school items, clothes, shoes and other collected items were distributed among the students.`,
  `Having such a great start, the foundation has done number of such activities in various places in Sri Lanka. Some of them would be,assisting the communities at the time of Tsunami and other natural disasters,helping elderly homes,conducting alms giving events,provision of ships and conducting various inspirational seminars in schools and the list goes on.`,
];

export const VISION = {
  content:
    "To build a just and equitable society with high level of human morality by creating an intellectually developed and socially balanced individual",
};

export const MISSION = [
  "Extend Support to the potential young individuals who have the ability and passion to grow and contribute to achieve the organization vision",
  "Focus on continuos development of the membership in the areas of their educational,professional and moral competencies, to be part of social development and to take up any challenges in the future with utmost confidence",
  "Contribute in social development by intervening to the most needed areas of support",
  "Inculcate sound ethics and moral values among the membership by doing various socio-religious activities",
];

export const FEEDBACK_CONTENT =
  "Leave your thoughts about Sasnaka Sansada that your feedback will encourage us to uplift and improve our services. Your comments and suggestions will enable us serve the world better.";

export const UPCOMING_EVENTS = {
  content:
    "There is not a specific time to serve towards the nation.Sasnaka Sansada has a lot to do Throughout the year.We are running so many different projects focusing on so many pillars, towards a one goal. If you are willing to take part or if you are curious to know, These are the upcoming projects for the year.",
  events: [
    {
      title: "Ganitha Saviya #75 episode",
      content:
        "Has a versatile career with expertise in Economics, Banking and Law.Employed as Development Specialist in an International bilateral development co-operation and counts over 12 years of experience in Development Finance and Banking",
    },
    {
      title: "Ganitha Saviya #75 episode",
      content:
        "Has a versatile career with expertise in Economics, Banking and Law.Employed as Development Specialist in an International bilateral development co-operation and counts over 12 years of experience in Development Finance and Banking",
    },
    {
      title: "Ganitha Saviya #75 episode",
      content:
        "Has a versatile career with expertise in Economics, Banking and Law.Employed as Development Specialist in an International bilateral development co-operation and counts over 12 years of experience in Development Finance and Banking",
    },
    {
      title: "Ganitha Saviya #75 episode",
      content:
        "Has a versatile career with expertise in Economics, Banking and Law.Employed as Development Specialist in an International bilateral development co-operation and counts over 12 years of experience in Development Finance and Banking",
    },
  ],
};

export const NOTIFICATIONS = {
  title: "සස්නක සංසද සිසුර සාදය උසස් පෙළ  ශිෂ්‍යත්ව ව්‍යාපෘතිය -2021/22",
  content:
    "2020 අ. පො.ස (සා/පෙළ) ව්භාගයට මුහුණ දූන් නායකත්වය කුසලතාවන් සහිත  ආර්ථිකමය අත්වැලක් අවැසි ශ්‍රී ලාංකේය දුවා දරුවන් වෙනුවෙන් සිසුර සාදය අධය්‍යාපනික ශිෂ්‍යත්ව ව්‍යාපෘතිය  මෙවරත් දියත් වීමට අප සූදානම්ය . ඔබත් සිසුර සාදය ශිෂ්‍යත්වය ලබන්නට සුදුස්සෙක් නම් ඔබ විසින් සකසා ගත් අයදුම්පත 2021/05/14 දිනට පෙර අප වෙත ලැබීමට සලස්වන්න .  අයදුම්පත් එවිය යුතු ලිපිනය",
  address: {
    line1: "සමිබන්දීකරණ ලේකම්",
    line2: "සස්නක සංසද සිසුර සාදය",
    line4: "155/34 පන්නිපිටිය පාර",
    line5: "බත්තරමුල්ල",
  },
  note: "ඹබගේ අයදුම් පතෙහි ඔබට පණියවිඩයක් ලබාදියහැකි දුරකන අංකයක් ද සදහන් කරන්න",
  forDetails: "වැඩිදුර විස්තර සදහා, විපුල බංඩාර (0775104903) අමතන්න",
};

export const PILLAR = {
  title: "STEAM",
  content:
    "The Sasnaka Sansada launched this pillar in motivating students to correctly identify the value and carrier opportunities of five main subject fields in Sri Lanka's education system based on S-SCience, T-Technology, E-Engineering, A-Art and M-Mathematics,. Students are guided through the process and we encourage them to achieve the goals though the fields which they are truly skilled in.",
  projects: [
    {
      title: "G.C.E ordinary level math workshops",
      content:
        "Originated in the year 2014, this project is predominantly done focussing the G.C.E Ordinary Level Students to ensure they receive the support and guidance they need to successfully face this critical examination. The workshops are conducted by Scholars of Mathematics from within our member base in all three languages.....",
    },
    {
      title: "G.C.E ordinary level math workshops",
      content:
        "Originated in the year 2014, this project is predominantly done focussing the G.C.E Ordinary Level Students to ensure they receive the support and guidance they need to successfully face this critical examination. The workshops are conducted by Scholars of Mathematics from within our member base in all three languages.....",
    },
    {
      title: "G.C.E ordinary level math workshops",
      content:
        "Originated in the year 2014, this project is predominantly done focussing the G.C.E Ordinary Level Students to ensure they receive the support and guidance they need to successfully face this critical examination. The workshops are conducted by Scholars of Mathematics from within our member base in all three languages.....",
    },
    {
      title: "G.C.E ordinary level math workshops",
      content:
        "Originated in the year 2014, this project is predominantly done focussing the G.C.E Ordinary Level Students to ensure they receive the support and guidance they need to successfully face this critical examination. The workshops are conducted by Scholars of Mathematics from within our member base in all three languages.....",
    },
    {
      title: "G.C.E ordinary level math workshops",
      content:
        "Originated in the year 2014, this project is predominantly done focussing the G.C.E Ordinary Level Students to ensure they receive the support and guidance they need to successfully face this critical examination. The workshops are conducted by Scholars of Mathematics from within our member base in all three languages.....",
    },
  ],
};

export const PILLAR_LIST = [
  "STEAM",
  "Leadership and Grooming",
  "Entrepreneurship",
  "Social and Community",
];
export const PILLAR_OBJECT = {
  steam: "STEAM",
  leadership: "Leadership and Grooming",
  entrepreneurship: "Entrepreneurship",
  social: "Social and Community",
};
export const PILLAR_FILTER = {
  STEAM: "STEAM",
  LEADERSHIP_AND_GROOMING: "Leadership and Grooming",
  ENTREPRENEURSHIP: "Entrepreneurship",
  SOCIAL_AND_COMMUNITY: "Social and Community",
};

export const PILLARS_FOR_FILTERS = {
  STEAM: "STEAM",
  LEADERSHIP_AND_GROOMING: "LEADERSHIP_AND_GROOMING",
  ENTREPRENEURSHIP: "ENTREPRENEURSHIP",
  SOCIAL_AND_COMMUNITY: "SOCIAL_AND_COMMUNITY",
};

// User Role Related

export const USER_ROLE = [
  { value: "ADMINISTRATOR", name: "Admin" },
  { value: "EDITOR_LEVELA", name: "Level A Editor" },
  { value: "EDITOR_LEVELB", name: "Level B Editor" },
  { value: "EDITOR_LEVELC", name: "Level C Editor" },
  { value: "EDITOR_LEVELD", name: "Level D Editor" },
];
export const ROLE_LIST = {
  ADMINISTRATOR: "Admin",
  EDITOR_LEVELA: "Level A Editor",
  EDITOR_LEVELB: "Level B Editor",
  EDITOR_LEVELC: "Level C Editor",
  EDITOR_LEVELD: "Level D Editor",
};

export const ROLE_FILTER = {
  ADMINISTRATOR: "ADMINISTRATOR",
  EDITOR_LEVELA: "EDITOR_LEVELA",
  EDITOR_LEVELB: "EDITOR_LEVELB",
  EDITOR_LEVELC: "EDITOR_LEVELC",
  EDITOR_LEVELD: "EDITOR_LEVELD",
};
