const PREFIX = `@@auth/`;

export const ASYNC_AUTH_INIT = `${PREFIX}ASYNC_AUTH_INIT`;
export const HANDLE_NOTIFICATION = `${PREFIX}HANDLE_NOTIFICATION`;
export const ASYNC_USER_INIT = `${PREFIX}ASYNC_USER_INIT`;
export const INITIALIZE_AUTH_PROCESS = `${PREFIX}INITIALIZE_AUTH_PROCESS`;

export const LOGIN_SUCCESS = `${PREFIX}LOGIN_SUCCESS`;
export const LOGOUT_SUCCESS = `${PREFIX}LOGOUT_SUCCESS`;
export const GET_USER_LIST = `${PREFIX}GET_USER_LIST`;
export const GET_USER_DETAILS_SUCCESS = `${PREFIX}GET_USER_DETAILS_SUCCESS`;
export const GET_SHAREABLE_LIST_SUCCESS = `${PREFIX}GET_SHAREABLE_LIST_SUCCESS`;
export const VERIFICATION_SUCCESS = `${PREFIX}VERIFICATION_SUCCESS`;
export const SIGN_UP_SUCCESS = `${PREFIX}SIGN_UP_SUCCESS`;
