import React, { PureComponent } from "react";

import "./styles.scss";

class Button extends PureComponent {
  static defaultProps = {
    className: "",
    onClick: () => {},
    loading: false,
    disabled: false,
    type: "button-secondary",
  };
  static TYPE = {
    MAIN: "button-main",
    SECONDARY: "button-secondary",
  };

  render() {
    const {
      className,
      onClick,
      loading,
      disabled,
      children,
      type,
    } = this.props;

    return (
      <button
        className={`${type} ${className} ${disabled ? "disabled" : undefined}`}
        onClick={onClick}
        disabled={disabled}
      >
        {loading ? <div className="loader-spin" /> : children}
      </button>
    );
  }
}

export default Button;
