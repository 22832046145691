import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Logo from "shared/logo/logo.png";
import profilePlaceholder from "shared/assets/profile.png";
import Button from "components/button";

import { logout } from "actions/auth";
import { ROLE_LIST } from "constants/organization";
import { isNotEmpty, formatURL } from "shared/utils";

import "./styles.scss";

function DashboardHeader({ details, loading, logout }) {
  const [showProfile, toggleProfile] = useState(false);

  const role = ROLE_LIST[details.roleId];

  const selectedRole = isNotEmpty(role) ? role : "Unknown";

  return (
    <Fragment>
      <div className="dashboard-header">
        <Link to="/">
          <div className="logo-wrapper">
            <img src={Logo} alt="logo" className="header-logo" />
          </div>
        </Link>
        <div className="header-role">SITE EDITOR</div>
        <div className="header-options">
          <div
            className={`profile-wrapper ${
              showProfile ? "profile-open" : undefined
            }`}
          >
            <img
              src={profilePlaceholder}
              alt="profile"
              onClick={() => toggleProfile(!showProfile)}
              className="profile-image"
            />
          </div>
        </div>
      </div>
      {showProfile && (
        <Fragment>
          <div className={`header-profile`}>
            <div className="profile-role">{selectedRole}</div>
            <div className="profile-image-wrapper">
              {isNotEmpty(details.profileImage) ? (
                <img src={formatURL(details.profileImage)} alt="pro-pic" />
              ) : (
                <div className="profile-image-main" />
              )}
            </div>
            <div className="profile-name">{details.name}</div>
            <div className="profile-email">{details.email}</div>
            <div className="button-container">
              <Link className="secondary-header-button" to="/dashboard/user">
                Edit Details
              </Link>
              <Button
                className="main-header-button"
                onClick={logout}
                loading={loading}
              >
                Log out
              </Button>
            </div>
          </div>
          <div
            className="header-backdrop"
            onClick={() => toggleProfile(!showProfile)}
          />
        </Fragment>
      )}
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    details: state.auth.details,
    loading: state.auth.loading,
  };
};

const Actions = {
  logout,
};

export default connect(mapStateToProps, Actions)(DashboardHeader);
