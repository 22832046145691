import {
  ASYNC_MEDIA_INIT,
  HANDLE_NOTIFICATION,
  GET_MEDIA_COORDINATOR_SUCCESS,
  GET_MEDIA_TEAM_SUCCESS,
  DELETE_MEDIA_COORDINATOR_SUCCESS,
} from "actionTypes/media";

const initialState = {
  loading: false,
  notification: null,
  mediaTeam: [],
  mediaCoordinator: {
    id: "",
    name: "",
    university: "",
    description: "",
    alumni: false,
    profileImage: null,
    type: "WEB",
  },
  isDeleted: false,
};

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case ASYNC_MEDIA_INIT:
      return {
        ...state,
        loading: true,
        notification: null,
      };
    case HANDLE_NOTIFICATION:
      return {
        ...state,
        notification: payload,
        loading: false,
      };
    case GET_MEDIA_TEAM_SUCCESS:
      return {
        ...state,
        mediaTeam: payload,
        loading: false,
        isDeleted: false,
      };
    case DELETE_MEDIA_COORDINATOR_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        loading: false,
      };
    case GET_MEDIA_COORDINATOR_SUCCESS:
      return {
        ...state,
        mediaCoordinator: {
          ...state.mediaCoordinator,
          ...payload,
        },
        loading: false,
      };
    default:
      return state;
  }
};
