import {
  ASYNC_VOLUNTEER_INIT,
  HANDLE_NOTIFICATION,
  GET_VOLUNTEERS_SUCCESS,
  SEND_DETAILS_SUCCESS,
} from "actionTypes/volunteer";

const initialState = {
  loading: false,
  notification: null,
  volunteers: [],
  isCreated: false,
};

export default (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case ASYNC_VOLUNTEER_INIT:
      return {
        ...state,
        loading: true,
        notification: null,
        isCreated: false,
      };
    case HANDLE_NOTIFICATION:
      return {
        ...state,
        notification: payload,
        loading: false,
      };
    case SEND_DETAILS_SUCCESS:
      return {
        ...state,
        isCreated: true,
        loading: false,
      };
    case GET_VOLUNTEERS_SUCCESS:
      return {
        ...state,
        volunteers: payload,
        loading: false,
      };
    default:
      return state;
  }
};
